<template>
    <div>
        <acms-v-form-field
            mode-input
            v-model="localSearch"
            label="Client data"
            @update:modelValue="updateFilter"
            class="w-400 acms-v-table-search me-3"
            placeholder="Search by name/phone/email"
            hide-hint
            elementClass="bg-gray-light text-primary-dark"
        >
        <template #right>
            <acms-v-icon name="search"/>
        </template>
        </acms-v-form-field>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import AcmsVFormField from "@ui/FormField/FormField.vue";

    export default {
        emits: ["update:filter"],
        setup(_, { emit }) {
            
            
            const localSearch = ref('');

            //const searchDelay = 300;
            // let timeSearch;
            // watch(localSearch, (val) => {
            //     clearTimeout(timeSearch);
            //     timeSearch = setTimeout(() => {
            //         emit('update:search', val);
            //     }, searchDelay);
            // });

            const updateFilter = () => {
                if (localSearch.value) { // Emit only if a date is selected
                    emit("update:filter", { key: "search", value: localSearch.value });
                }
            };
            return { localSearch, updateFilter };
        }
    }
</script>
<style lang="scss">
    .acms-v-table-search {
        max-width: 432px;
        width:260px;
    }
    .acms-v-form-element-input{
        background-color: transparent !important;
    }
</style>