import { onMounted, reactive, ref, watch} from 'vue';
import {cloneDeep} from 'lodash';
import {getRoute} from "@plugins/useRoutes";
import {useForm} from "@inertiajs/vue3";
import {useBackendMiddlewarePropsService} from "@services";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
export default function ({props, cleanItem, dropdownOptions, selectOptions}, emit) {
    const {backendFlashSuccess} = useBackendMiddlewarePropsService()
    const AlertsManager = useAlertsManagerStore()
    const valueList = ref([]);
    const deleteIds = ref([])
    const modalsState = reactive({
        modalRequestEditShow: false,
        modalRequestEditData:{},
        modalRequestDeleteShow: false,
        modalRequestDeleteData:{},
        modalRequestWrongShow: false,
        modalRequestWrongData:{},

        modalEditShow: false,
        modalEditData:{},
        modalDeleteShow: false,
        modalDeleteData:{},
    });

    const getCleanItem = () => ({
        ...cleanItem,
        main: valueList.value?.length == 0,
        _wasCreated: true,
    })

    watch(() => props.updatedData, (val) => {
        if (Array.isArray(val) && val[0]?.pivot) {
            parseDataArray(val, true)
        } else if (val?.pivot) {
            parseDataArray([val], true)
        } else {
            valueList.value = val
        }
    })
    watch(valueList, (val) => {
        emit('update:modelValue', val);
    }, {deep: true});

    const clickOnDropdownOption = ({option,value}, key) => {
        if (option?.canSetMain) {
            valueList.value = cloneDeep(valueList.value).map((item, index) => {
                item.main = key == index;
                return item;
            });
        }
        if (option?.title == 'Request edit') {
            modalsState.modalRequestEditShow = true;
            modalsState.modalRequestEditData = value
        }
        if (option?.title  == 'Request delete' ) {
            modalsState.modalRequestDeleteShow = true;
            modalsState.modalRequestDeleteData = value
        }
        if (option?.title == 'Wrong') {
            modalsState.modalRequestWrongShow = true;
            modalsState.modalRequestWrongData = value
        }
        if (option?.title == 'Edit') {
            modalsState.modalEditShow = true;
            modalsState.modalEditData = value
        }
        if (option?.title  == 'Delete' ) {
            modalsState.modalDeleteShow = true;
            modalsState.modalDeleteData = value
        }
    };

    const getOrderClass = (key) => valueList.value[key]?.main ? 'order-0' : 'order-1';

    const add = () => {
        if (!Array.isArray(valueList.value)) {
            valueList.value = []
        }

        valueList.value.push(getCleanItem());
    };
    const getLabel = (key, mainTitle, title) => {
        return valueList.value[key]?.main ? mainTitle : title;
    };

    const parseDataArray = (data, update = false) => {
        if (data[0]?.phone) {
            const copyList = cloneDeep(valueList.value)
            const dataValue = data?.value ?? data

            const dropdownOptionsClone = cloneDeep(dropdownOptions.value)

            if (copyList.length == dataValue.length || dataValue.length > copyList.length) {
                valueList.value = dataValue.map(({pivot, phone, dial_code_object, id}) => {
                    return {
                        main: pivot.main,
                        type: dropdownOptionsClone.find(item => item.id == pivot.type_id),
                        text: phone,
                        phoneCode: dial_code_object,
                        id,
                        _wasParsed: true,
                    };
                });
            }
        } else if (data[0]?.email) {
            const copyList = cloneDeep(valueList.value)
            const dataValue = data?.value ?? data
            const dropdownOptionsClone = cloneDeep(dropdownOptions.value)
            if (copyList.length == dataValue.length || dataValue.length > copyList.length) {
                valueList.value = dataValue.map(({pivot, email, id}) => {
                    return {
                        main: pivot.main,
                        type: dropdownOptionsClone.find(item => item.id == pivot.type_id),
                        text: email,
                        id,
                        _wasParsed: true,
                    };
                });
            }
        } else if (data[0]?.airline_id) {
            valueList.value = data.map(({code, airline, id}) => {
                return {
                    id,
                    ff_program: airline,
                    text: code,
                    _wasParsed: true,
                };
            });
        }
    }
    const removeRow = (key) => {
        const id = valueList.value[key]?.id
        if (id) {
            deleteIds.value.push(id)
            emit('update:deleteIds', deleteIds.value)
        }
        valueList.value.splice(key, 1);
    }
    const dropdownOptionShowFun = (option) => {
        if (option.action == 'delete') {
            return valueList.value.length > 1
        }
        return true
    }

    const itemDisabledStateFun = () => {
        if (props.modeCreate) {
            return false
        }
        return true
    }


    const onModalHideHandle = () =>{

        modalsState.modalEditData = {}
        modalsState.modalDeleteData = {}
        modalsState.modalRequestEditData = {}
        modalsState.modalRequestDeleteData = {}
        modalsState.modalRequestWrongData = {}
    }

    const onDeleteContactHandle = (type) => {

        const {modalDeleteData} = modalsState
        const form = useForm({
            id: modalDeleteData.id,
            type,
        })
        form.post(getRoute('clients.contact.delete'), {
            preserveScroll: true,
            preserveState:false,
            onSuccess() {
                if(backendFlashSuccess.value){
                    AlertsManager.add(backendFlashSuccess.value)
                    modalsState.modalDeleteShow = false
                }
            }
        })
    }

    onMounted(() => {
        const {modelValue} = props;
        if (props.initEmpty && (!modelValue || modelValue?.length == 0)) {

        } else {
            if (modelValue && modelValue?.length > 0 || modelValue?.length > 0 && props.initEmpty) {
                if (modelValue[0]?.pivot || modelValue[0]?.airline_id) {
                    parseDataArray(modelValue)
                } else {
                    valueList.value = modelValue?.map(item => {
                        if (!item) {
                            return cloneDeep(getCleanItem())
                        }
                        return item
                    })

                }
            } else if (!modelValue) {
                valueList.value = [];
            } else if (modelValue?.length == 0) {
                valueList.value = [cloneDeep(getCleanItem())];
            }
        }
    });

    return {
        valueList,
        clickOnDropdownOption,
        dropdownOptionShowFun, itemDisabledStateFun,
        getOrderClass,
        add,
        getLabel,
        modalsState,
        removeRow,
        onDeleteContactHandle,
        onModalHideHandle,
    };
}
