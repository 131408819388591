<template>
    <div>
        <label class="client-source-label">Client Source</label> <!-- Label above -->
        <v-select
            v-model="selectedSource"
            :items="sourceOptions"
            class="client-source-select"
            clearable
            dense
            variant="outlined"
            density="compact"
            @update:modelValue="updateFilter" 
        ></v-select>
    </div>
 </template>
  
<script>
  import { ref } from "vue";
  
  export default {
    emits: ["update:filter"],
    setup(_, { emit }) {
        const selectedSource = ref(null);
        const sourceOptions = [
            { title: 'Any', value: null },
            { title: 'Company', value: 'Company' },
            { title: 'Personal', value: 'Personal'},
            { title: 'Referral', value: 'Referral' },
        ];
  
        const updateFilter = () => {
            emit("update:filter", { key: "ClientSource", value: selectedSource.value });
        };
  
        return { sourceOptions, selectedSource, updateFilter};
    }
  };
</script>
<style scoped>
    .client-source-select {
        width: 175px; /* Adjust width */
        max-width: 100%; /* Prevent overflow */
    }
    .client-source-label {
        font-weight: bold;
        margin-bottom: 7px;
        display: block;
        font-size: 14px;
    }
</style>