<template>
    <div class="acms-v-table-header d-flex mb-4">
        <acms-v-form-field v-if="showBlocks.search"
                           mode-input
                           v-model="localSearch"
                           class="w-100 acms-v-table-search me-3"
                           :placeholder="searchPlaceholder"
                           hide-hint
                           elementClass="bg-gray-light text-primary-dark"
        >
            <template #right>
                <acms-v-icon name="search"/>
            </template>
        </acms-v-form-field>

        <div v-if="showBlocks.filters">
           <filters-wrapper :agents="agents"></filters-wrapper>
        </div>

        <div class="ms-auto d-flex" :class="showBlocks.filters ? 'filters-align' : ''">
            <div class="me-2" v-if="showBlocks.entries">
                <acms-v-select
                    :options="entriesOptions"
                    v-model="localEntries"
                    placeholder="Set count"
                />
            </div>
            <v-dialog v-if="!hideAssignAgentBtn" v-model="dialog" max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn
                        v-bind="activatorProps"
                        color="surface-variant"
                        variant="flat"
                        style="margin-right: 6px"
                    >
                        Assign Agent
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>Agent details</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="selectedAgent"
                                        :items="agents"
                                        item-title="name"
                                        item-value="id"
                                        label="Select Agent"
                                        multiple
                                        chips
                                        closable-chips
                                        clearable
                                        :search-input.sync="search"
                                        @update:search="filterSearch"
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    No agents found
                                                </v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="assignAgent">Assign</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-if="showDeleteButton" v-model="dialogDeleteRequest" max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn
                        v-bind="activatorProps"
                        color="danger"
                        variant="outlined"
                        style="margin-right: 6px"
                    >
                        Delete {{props.deleteTitle}}
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>{{props.deleteTitle ? props.deleteTitle + ':' : '' }}Confirm delete</v-card-title>
                    <v-card-text>
                        Are you sure to want to delete?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="Danger" @click="deleteRequest">Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <template v-if="showBlocks.add">
                <acms-v-btn v-if="addHref"
                            :title="addTitle"
                            class="btn-primary width-max-content ms-auto"
                            icon="plus"
                            :href="addHref"
                />
                <acms-v-btn v-else
                            :title="addTitle"
                            class="btn-primary width-max-content ms-auto"
                            icon="plus"
                            @click="$emit('add')"
                />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AcmsVTableHeader',
    props: {
        agents: {
            type: Array,
            required: true
        },
        hideAssignAgentBtn: {
            type: Boolean,
            default: true
        },
        hideFilters:{
            type: Boolean,
            default: true
        },
        showDeleteButton:{
            type: Boolean,
            default: false
        },
        deleteTitle: {
            type: String,
            default: ''
        }
    },
    computed : {
        filteredAgents() {
            return this.agents;
        }},
    data() {
        return {
            dialog: false,
            dialogDeleteRequest: false,
            selectedAgent: null
        };
    },
    methods: {
        assignAgent() {
            this.$emit('assign-agent', {
                agent: this.selectedAgent
            });
            this.dialog = false;
        },
        deleteRequest() {
            this.$emit('delete-request');
            this.dialogDeleteRequest = false;
        },
        filterSearch(val) {
            // Здесь вы можете реализовать логику фильтрации, если это необходимо
            // Например, вы можете отфильтровать агентов на основе введенного текста
            if (val) {
                const lowercaseSearch = val.toLowerCase();
                this.filteredAgents = this.agents.filter(agent =>
                    agent.name.toLowerCase().includes(lowercaseSearch)
                );
            } else {
                this.filteredAgents = this.agents;
            }
        }
    },
    watch: {
        agents: {
            immediate: true,
            handler(newAgents) {
                this.filteredAgents = newAgents;
            }
        }
    }
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';
import AcmsVFormField from "@ui/FormField/FormField.vue";
import AcmsVIcon from "@ui/Icon.vue";
import AcmsVSelect from "@ui/FormField/elements/Select/Select.vue";
import FilterAgent from "@boxes/request/filters/FilterAgent.vue";
import FiltersWrapper from '@boxes/request/filters/FiltersWrapper.vue';


const props = defineProps({
    entries: [Number, String],
    entriesOptions: {
        type: Array,
        default: () => [20, 50, 100]
    },
    search: String,
    searchDelay: {
        type: Number,
        default: 300,
    },
    searchPlaceholder: {
        type: String,
        default: 'Search'
    },
    addTitle: String,
    addHref: String,
    config: String,
    agents: Array,
    showDeleteButton:{
        type: Boolean,
    },
    deleteTitle: {
        type: String,
        default: ''
    },
    hideAssignAgentBtn: Boolean, // Новый пропс для скрытия кнопки назначения агента
    hideFilters: Boolean // prop to hide filters
});
const emit = defineEmits(['update:entries', 'update:search', 'add', 'assign-agent','filter-agent', 'delete-request']);

const showBlocks = computed(() => {
    const {config} = props;
    return {
        search: config?.includes('search'),
        entries: config?.includes('entries'),
        add: config?.includes('add'),
        filterAgent: config?.includes('filter-agent'),
        filters: config?.includes('filters')
    };
});

const localEntries = ref(props.entries ?? 20);
const localSearch = ref(props.search ?? '');

watch(localEntries, (val) => {
    emit('update:entries', val);
});

let timeSearch;
watch(localSearch, (val) => {
    clearTimeout(timeSearch);
    timeSearch = setTimeout(() => {
        emit('update:search', val);
    }, props.searchDelay);
});
</script>

<style lang="scss">
.acms-v-table-search {
    max-width: 432px;
}
.filters-align{
    margin-top: 28px;
}
</style>
