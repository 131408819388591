import {
    MODEL_PQ_AWARD_TAXES_LABELS,
    MODEL_PQ_PASSENGERS_TYPES,
    } from "@models/priceQuote.model";

const getTaxesObject = () => {
    return Object.fromEntries(MODEL_PQ_AWARD_TAXES_LABELS.map(item => [item.toLowerCase(), null]))
}

export const modelCommonPqSetInitialDataForObject = (passengers) => {
    const object = {};

    MODEL_PQ_PASSENGERS_TYPES.forEach(typeKey => {
        // Check if the typeKey exists in passengers.objects and its value is greater than 0
        if (passengers.objects?.[typeKey] > 0) {
            // Initialize object[typeKey] as an empty object if it does not exist
            if (!object[typeKey]) {
                object[typeKey] = {};
            }

            // Set the properties
            object[typeKey].price = Number(0);
            object[typeKey].mark_up = Number(0);
            object[typeKey].commission = Number(0);
            object[typeKey].if = Number(0);
        }
    });

    return object;
};

export const modelCommonPqSetPriceObjectData = ({passengers, item, oldItem}) => {
    console.log('oldItem', oldItem);
    const {ticket, type, pcc, mileage, id} = item
    if (type.title == 'Revenue') {
        return {
            type,
            ticket,
            id: id ?? null,
            pcc: pcc ?? null,
            data: (oldItem && oldItem?.type.id == type.id) ? oldItem.data : modelCommonPqSetInitialDataForObject(passengers)
        }
    }
    if (type.title == 'Online') {
        return {
            type,
            ticket,
            id: id ?? null,
            pcc: null,
            mileage: null,
            data: (oldItem && oldItem?.type.id == type.id) ? oldItem.data : modelCommonPqSetInitialDataForObject(passengers)
        }
    }
    if (type.title === 'Fantastic') {
        return {
            type,
            ticket : 1,
            id: id ?? null,
            pcc: null,
            mileage: null,
            data: (oldItem && oldItem?.type.id == type.id) ? oldItem.data : modelCommonPqSetInitialDataForObject(passengers)
        }
    }

    if (type.title == 'Award') {

        const initialData = {
            mileage,
        }
        const sameOldDataType = oldItem && oldItem.type.id == type.id

        const data = passengers.entries.reduce((acc, [passItemKey, passItemValue]) => {
            if (passItemValue > 0) {

                if (sameOldDataType && oldItem?.data?.[passItemKey]) {
                    acc[passItemKey] = oldItem?.data[passItemKey]
                } else {
                    acc[passItemKey] = getTaxesObject()
                }
            }
            return acc
        }, initialData)

        return {
            type,
            ticket,
            mileage: mileage ?? null,
            data
        }
    }


}
