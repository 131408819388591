<template>
    <div class="box-phone-numbers">
        <div class="d-flex flex-column">
            <template v-for="(item, key) in valueList">
                <acms-v-form-field
                    :label="getLabel(key, 'Main phone number', 'Phone number ')"
                    :labelClasses="{'disabled': Boolean(valueList[key]?.id) }"
                    :name="`'phone_number_'+key`"
                    required
                    :class="[getOrderClass(key), 'phone-select-block']"
                    placeholder="(XXX) XXX-XXXX"
                    :modeInputWithDropdown="{
                                     dropdownOptions,
                                     selectOptions: getInitialDialCodeOption,
                                     selectedOptionOnInit:0,
                                     phoneCode:true,
                                     keyDropdown:'type',
                                     keySelect:'phoneCode',
                                     mode:'phone',
                                      search:true,
                                      searchAsync: 'infohub.getDialCodes',
                                      placeholderSelect:'Search',
                                      dropdownOptionShowFun: dropdownOptionShowFun,
                                      disabledStateFun:itemDisabledStateFun,
                                }"
                    type="text"
                    @clickOnDropdownOption="(e)=>clickOnDropdownOption(e, key)"
                    v-mask="'(###) ###-####'"
                    v-model="valueList[key]"
                    :errors="errors?.[`phone_numbers.${key}`]"
                />
            </template>
        </div>
        <acms-v-btn
            title="Add phone number"
            preset="simple-primary"
            icon="plus"
            @click="add"
        />
        <teleport to="body">
            <ModalRequestEditEmailOrPhone
                v-model:show="modalsState.modalRequestEditShow"
                :data="modalsState.modalRequestEditData"
                @update:show="onModalHideHandle"
                type="phone"
            />
            <ModalRequestDeleteEmailOrPhone
                v-model:show="modalsState.modalRequestDeleteShow"
                :data="modalsState.modalRequestDeleteData"
                @update:show="onModalHideHandle"
                type="phone"
            />

            <ModalRequestWrongEmailOrPhone
                v-model:show="modalsState.modalRequestWrongShow"
                :data="modalsState.modalRequestWrongData"
                @update:show="onModalHideHandle"
                type="phone"
            />

            <ModalEditEmailOrPhone
                v-model:show="modalsState.modalEditShow"
                :data="modalsState.modalEditData"
                @update:show="onModalHideHandle"
                type="phone"
                title="Phone"
            />

            <AlertDelete
                v-model:show="modalsState.modalDeleteShow"
                @delete="()=>onDeleteContactHandle('phone')"
                type="phone"
            />


        </teleport>

    </div>
</template>

<script>
export default {
    name: 'BoxPhoneNumbers',
};
</script>
<script setup="">
import useCommonEmailsAndPhones from './composables/useCommonEmailsAndPhones';
import {inputDropdownOptions} from '@demo/demoOptionsForSelect';
import { cloneDeep } from 'lodash';
import {computed, reactive} from "vue";
import {usePage} from "@inertiajs/vue3";
import ModalRequestDeleteEmailOrPhone from "./partials/ModalRequestDeleteEmailOrPhone.vue";
import ModalRequestEditEmailOrPhone from "./partials/ModalRequestEditEmailOrPhone.vue";
import ModalRequestWrongEmailOrPhone from "./partials/ModalRequestWrongEmailOrPhone.vue";
import {useBackendMiddlewarePropsService} from "@services";
import ModalEditEmailOrPhone from "@boxes/common/partials/ModalEditEmailOrPhone.vue";
import AlertDelete from "@alerts/AlertDelete.vue";
import { VueMaskDirective } from 'vue-the-mask';
const $page = usePage()
const props = defineProps({ modelValue: Array,
    updatedData: [Array, Object],
    errors:Object,
    modeCreate:Boolean,
});
const {backendPermissions} = useBackendMiddlewarePropsService()

const getInitialDialCodeOption = computed(()=> [$page.props.optionsForSelect?.dialCodeOption])

const emit = defineEmits(['update:modelValue']);

const actionEdit = { 'title': 'Edit' , type:'action', action: 'edit'};
const actionRequestEdit = { 'title': 'Request edit' , type:'action', action: 'request-edit'};

const actionDelete =  { 'title': 'Delete', 'className': 'text-danger', type:'action', action: 'request-delete'}
const actionRequestDelete =  { 'title': 'Request delete', 'className': 'text-danger', type:'action', action: 'delete'}


const selectOptions = [
    { id: 1, title: 'America', thumbnail: '/images/phoneCodes/usa.png', code: '1' },
    { id: 2, title: 'Moldova', thumbnail: '/images/phoneCodes/usa.png', code: '373' },
];
const dropdownOptions = computed(()=>{
    const actions = [
        { 'divider': true, type:'divider' }
    ]

    actions.push(backendPermissions?.allowUpdateClientPhoneNumber ? actionEdit : actionRequestEdit)
    actions.push(backendPermissions?.allowDeleteClientPhoneNumber ? actionDelete : actionRequestDelete)

    return [
        { 'title': 'Set as main', 'canSetMain': true, 'title_main': 'Main phone' },
        ...inputDropdownOptions,
        ...actions
    ];
})
const cleanPhoneNumber = {
    main: false,
    type: cloneDeep(dropdownOptions.value?.[2]),
    phoneCode: null,
    text: null,
    // id:null,
};

const {
    valueList, clickOnDropdownOption,
    getOrderClass,
    add,
    getLabel,
    modalsState,
    dropdownOptionShowFun,itemDisabledStateFun,
    onModalHideHandle,
    onDeleteContactHandle
} = useCommonEmailsAndPhones({
    props,
    cleanItem: cleanPhoneNumber,
    dropdownOptions,selectOptions,
}, emit);
</script>

<style lang="scss" >
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.phone-select-block .acms-v-select-toggle{
    min-width: 100px;
    max-width: 135px;

    .code{
        margin-right: 0!important;
    }
}
</style>
