<template>
    <div>
        <acms-v-form-field
            label="Destination cities"
            class="flight-leg-select"
            placeholder="Destination City"
            v-model="destinationCities"
            @update:modelValue="updateFilter"
            :modeSelect="{
                searchPlaceholder:  'Enter min 3 characters to search',
                search:true,
                searchAsync: 'infohub.getAirportAndCityList',
                selectedOptionOnInit: 0,
                outValueKey:'id',
                optionKeyTitle: getOptionKeyTitle,
                toggleTagKeyTitle: 'iata',
                multi:true
            }"
        />
    </div>
 </template>
  
<script>
  import { ref } from "vue";
  
  export default {
    emits: ["update:filter"],
    setup(_, { emit }) {
      const destinationCities = ref([]);
  
      const updateFilter = () => {
        const selectedIds = destinationCities.value.map(city => city.id);
        emit("update:filter", { key: "destinationCities", value: selectedIds });
      };

      const getOptionKeyTitle = (city) => {
        if (!city) return ""; // Fallback if data is missing
        const countryName = city.option?.country?.title || "Unknown Country";
        const cityName = city?.option?.title || "Unknown City";
        const iata = city?.option?.iata
        return `(${iata}) ${countryName}, ${cityName}`; // Format as "(IATA) Country, City"
      };
        
      return { destinationCities, updateFilter, getOptionKeyTitle};
    }
  };
  </script>