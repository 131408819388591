<template>
    <div class="box-table-requests">
        <acms-v-table
            :class="tableClass"
            show-number
            :columns="{
                'created-at': 'Creation date',
                'time-passed': 'Time passed',
                'client_active': '',
                'client': 'Client',
                'id': 'Request ID',
                'local-time': 'Local time',
                'flight-details': 'Flight details',
                'passengers': 'Passengers',
                'pq': 'PQ',
                'fu-status': 'F/U',
                'email-status': 'Email',
                'oaf-status': 'OAF',
                'cabin-class': 'Cabin class',
                'agents': 'Agents',
            }"
            :columns-width="{
                '#': '52px',
                'id': '124px',
                'client_active': '50px',
                'client': '170px',
                'agents': '160px',
                'created-at': '150px',
                'time-passed': '135px',
                'local-time': '135px',
                'flight-details': '280px',
                'passengers': '200px',
                'pq': '50px',
                'cabin-class': '160px',
                'fu-status': '50px',
                'email-status': '50px',
                'oaf-status': '50px',
            }"
            :columns-cell-class="{
                '#': 'white-space-nowrap',
                'time-passed': 'justify-content-end',
                'local-time': 'justify-content-end'
            }"
            :columns-title-class="{
                'time-passed': 'justify-content-end',
                'local-time': 'justify-content-end'
            }"
            rowChildrenKey="request_children"
            :data="collection?.data ?? []"
            :links="collection?.meta?.links ?? []"
            :page="page"
            @update:page="$emit('update:page', $event)"
            sortKeys="id,created-at"
            @update:sort="handleSort"
            emptyStateTitle="Requests"
            :search="search"
            :entriesLimit="collection.meta.per_page"
            :currentPage="collection.meta.current_page"
            @update:selectedRows="$emit('update:selectedRows', $event)"
            @handle:assignAgent="$emit('handle:assignAgent', $event)"
            @handle:deleteRequest="$emit('handle:deleteRequest', $event)"
        >
            <template #header>
                <AcmsVTableHeader
                    :config="getHeaderConfig"
                    :search="search"
                    @update:search="$emit('update:search', $event)"
                    :entries="entries"
                    @update:entries="$emit('update:entries', $event)"
                    :agents="agents"
                    addTitle="Add request"
                    @add="$emit('addButtonClick', $event)"
                    :hide-assign-agent-btn="hideAssignAgentBtn"
                    :show-delete-button="showDeleteButton"
                    delete-title="Request"
                    @assign-agent="$emit('handle:assignAgent', $event)"
                    @delete-request="$emit('handle:deleteRequest', $event)"
                />
            </template>
            <template #bodyCell-id="{ item }">
                <Link :href="getRoute('requests.edit', item.id)" class="text-primary text-center fw-semibold">
                    {{ item.id }}
                </Link>
            </template>
            <template #bodyCell-client-active="{ item }">
                <span v-if="item.client_active">
<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none" stroke="#028001">
<g id="VIP_bgCarrier" stroke-width="0"/>
<g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
<g id="VIP_iconCarrier">
<path fill="#028001" d="M8 3a5 5 0 100 10A5 5 0 008 3z"/>
</g>
</svg>
                </span>
                <span v-else>
<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none" stroke="#d34545">
<g id="VIP_bgCarrier" stroke-width="0"/>
<g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
<g id="VIP_iconCarrier">
<path fill="#d34545" d="M8 3a5 5 0 100 10A5 5 0 008 3z"/>
</g>
</svg>
                </span>
            </template>
            <template #bodyCell-client="{ item }">
                <Link :href="getRoute('clients.edit', item.client_id)" class="text-primary text-center fw-semibold">
                    {{ item.client_full_name }}
                </Link>
            </template>
            <template #bodyCell-agents="{item}">
                <span v-for="(agent, index) in item.agents" :key="agent.agent_id">
            {{ agent.first_name }}<span v-if="index < item.agents.length - 1">, </span>
        </span>
            </template>
            <template #bodyCell-created-at="{ item }">
                <span>{{ item.created_at }}</span>
            </template>
            <template #bodyCell-time-passed="{ item }">
                <div class="text-end">{{ item.time_passed }}</div>
            </template>
            <template #bodyCell-local-time="{ item }">
                <div class="text-end">{{ item.local_time }}</div>
            </template>
            <template #bodyCell-flight-details="{ item }">
                <EntityFlightDetails :details="item.flight_details"/>
            </template>
            <template #bodyCell-passengers="{ item }">
                <EntityCellPassengers :data="{ adults: item.adults, children: item.children, infants: item.infants }"/>
            </template>
            <template #bodyCell-pq="{ item }">
                <span>{{ item.pqs_count ?? '0' }}</span>
            </template>
            <template #bodyCell-fu-status="{ item }">
                 <span style="padding: 0; margin: 0" v-if="item.fu_status">
                              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" :fill="'green'">
                                                                            <path d="M480-432 168-611v347h384v72H168q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.15Q864-725.7 864-696v264h-72v-179L480-432Zm0-85 312-179H168l312 179ZM768-84l-51-51 57-57H624v-72h150l-57-57 51-51 144 144L768-84ZM168-611v381-466 85Z"/>
                                                                        </svg>
                            </span>
            </template>
            <template #bodyCell-oaf-status="{ item }">
                  <span v-if="item.oaf_status">
                                 <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#48752C"><path d="M80-160v-640h800v640H80Zm80-80h640v-480H160v480Zm0 0v-480 480Zm160-40h80v-40h40q17 0 28.5-11.5T480-360v-120q0-17-11.5-28.5T440-520H320v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T240-600v120q0 17 11.5 28.5T280-440h120v40H240v80h80v40Zm320-30 80-80H560l80 80Zm-80-250h160l-80-80-80 80Z"/></svg>
                            </span>
            </template>
            <template #bodyCell-email-status="{ item }">
                 <span style="padding: 0; margin: 0" v-if="item.email_status">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF51" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
                            </svg>
                            </span>
            </template>
            <template #bodyCell-cabin-class="{ item }">
<!--                <span>{{ item }}</span>-->
                <div style="display: flex; margin-right: 4px;">
                    <div
                        v-for="(cabin_class_item, idx) in item.cabin_class_id"
                        :key="idx"
                        :style="getIconStyle(cabinColor(cabin_class_item))"
                    >
                        <div>
                        </div>
                        <span v-tooltip="'CABIN TYPE : ' + cabin(cabin_class_item)">{{ cabin_class_item}}</span>
                    </div>
                </div>
            </template>
        </acms-v-table>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { Link } from "@inertiajs/vue3";
import AcmsVTableHeader from '@ui/Table/partials/TableHeader.vue';
import EntityFlightDetails from "../../entities/EntityFlightDetails.vue";
import EntityCellPassengers from "../../entities/EntityCellPassengers.vue";
import { getCabinClassString } from "@helpers/commonHelpers";
import AcmsVTable from "@ui/Table/Table.vue";

const props = defineProps({
    collection: [Object, Array],
    hideAddBtn: Boolean,
    addTitle: String,
    search: String,
    entries: [String, Number],
    page: [String, Number],
    tableClass: [String, Object, Array],
    agents: Array,
    hideAssignAgentBtn: Boolean,
    hideFilters: Boolean,
    showDeleteButton: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits([
    'update:modelValue',
    'sortChange',
    'update:page',
    'update:search',
    'update:entries',
    'addButtonClick',
    'update:selectedRows',
    'handle:assignAgent',
    'handle:deleteRequest'
]);

const handleSort = (e) => {
    emit('sortChange', e);
};

const getHeaderConfig = computed(() => {
    let config = 'entries';
    if (!props.hideAddBtn) {
        config += ',add';
    }
    if (!props.hideAssignAgentBtn) {
        config += ',assign-agent';
    }
    if (!props.hideFilters) {
        config += ',filters';
    }
    return config;
});

const cabin = (cabinClass) => {
    switch (cabinClass) {
        case "B":
            return 'Business';
        case 'E':
            return 'Economy';
        case 'F':
            return 'First';
        case 'P':
            return 'Premium Economy';
        default:
            return 'Undefined';
    }
}

const getIconStyle = (color) => {
    return {
        width: '18px',
        height: '18px',
        border: `1px solid ${color}`,
        color: color,
        borderRadius: '3px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontSize: '12px',
        marginRight: '4px',
    };
};

const cabinColor = (cabin) => {
    // console.log('cabin', cabin);
    switch (cabin) {
        case 'E':
            return "#D97706";
        case 'B':
            return "#556B2F";
        case 'P':
            return "#2563EB";
        case 'F':
            return "#d92626";
        default:
            return "#556B2F";
    }
}
</script>

<style scoped>
/* Your styles here */
</style>
