<template>
    <acms-v-collapse
        :items="items"
        @update:activeIndex="setOpenedRequest"
        :active-index="locState.requestOpenedIndex"
        toggleIcon="pen"
    >
        <template #toggle="{item, index, number}">
            <div class="d-flex flex-column w-100">
                <div class="request-row-titles-template">
                    <div class="request-row-titles-template-item fz-14">{{ number }}</div>
                    <div class="request-row-titles-template-item text-uppercase fz-14 d-flex">
                        <template v-if="item.parent_id">
                            <div class="table-cell-ico-child "></div>
                        </template>
                        <button class="text-primary fw-semibold"
                                @click="()=>setOpenedRequest(locState.requestOpenedIndex === index ? -1 : index)">
                            {{item.id }}
                        </button>
                    </div>
                    <div class="request-row-titles-template-item  request-row-titles-template-item-desk fz-14">
                        <EntityFlightDetails :details="item.flight_details"/>
                    </div>
                    <div class="request-row-titles-template-item">
                        <EntityCellPassengers
                            :data="{ adults: item?.adults, children: item?.children, infants: item?.infants }"/>
                    </div>
                </div>
            </div>

        </template>
        <template #content="{item, closeHandle}">
            <BoxClientRequestEdit
                :mainClientId="clientId"
                :modelValue="item"
                @cancel="closeHandle"
                @saved="closeHandle"
                :responseOnlyKey="responseOnlyKey"
            />
        </template>
    </acms-v-collapse>

</template>
<script>
export default {
    name: "PartialRequestsAccordion",
}
</script>
<script setup="">
import BoxClientRequestEdit from "@boxes/common/BoxClientRequestEdit.vue";
import {onMounted, reactive, watch} from "vue";
import EntityFlightDetails from "@entities/EntityFlightDetails.vue";
import EntityCellPassengers from "@entities/EntityCellPassengers.vue";
const props = defineProps({
      clientId:[String,Number],
      items:Array,
      responseOnlyKey: Array,
      activeIndex: Number,
})
const findIndex = (newId) => {
    if (newId !== undefined && props.items) {
        // Находим индекс элемента с соответствующим id
        return props.items.findIndex(item => item.id === newId);
    }
}

const locState = reactive({
    requestOpenedIndex: findIndex(props.activeIndex) ?? -1
})
const setOpenedRequest = (index) => {
    locState.requestOpenedIndex = index
}
watch(() => props.activeIndex, (newId) => {
    locState.requestOpenedIndex = findIndex(newId);
});
</script>
<style scoped lang="scss">
</style>
