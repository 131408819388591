<template>
    <div class="departure-date">
        <acms-v-form-field
          label="Departure date"
          placeholder="Select period"
          :modeDatepicker="{
            datesRange:true,
          }"
          v-model="departureDate"
          @update:modelValue="updateFilter"
          @click="addResetButton"
        />
    </div>
 </template>
  
  <script>
    import { ref } from "vue";
    
    export default {
      emits: ["update:filter"],
      setup(_, { emit }) {
        const departureDate = ref("");
    
        const updateFilter = () => {
          if (departureDate.value[0]) { // Emit only if a date is selected
            emit("update:filter", { key: "departureDate", value: departureDate.value });
          }
        };

        const addResetButton = () => {
          setTimeout(() => {
            const datepickerFooter = document.querySelector(".action-row");
            if (datepickerFooter && !datepickerFooter.querySelector(".reset-btn")) {
              document.querySelector(".close-button").classList.remove('ms-auto')
              const resetBtn = document.createElement("button");
              resetBtn.innerText = "Reset";
              resetBtn.classList.add("reset-btn");
              resetBtn.classList.add("close-button");
              resetBtn.classList.add("ms-auto");
              resetBtn.style.marginRight= '5px';
              resetBtn.style.background = 'red';
              resetBtn.onclick = () => {
                departureDate.value = []
                emit("update:filter", { key: "departureDate", value: [] });
              };
              datepickerFooter.prepend(resetBtn);
            }
          }, 100);
        };
    
        return { departureDate, updateFilter, addResetButton };
      }
    };
  </script>

  <style scoped>
    div:has(.dp__input_wrap) {
      width: 200px;
    }
    ::v-deep(.dp__input_wrap) {
        width: 200px;
    }
  </style>