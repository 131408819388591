<template>
  <div>
    <div class="custom-autocomplete">
      <label class="agents-label">Agents</label> <!-- Label above -->
      <v-autocomplete
        v-model="selectedAgents"
        class="agents-autocomplete"
        :items="agents"
        placeholder="Select agents"
        item-title="name"
        item-value="id"
        multiple
        chips
        closable-chips
        clearable
        variant="outlined"
        density="compact"
        :search-input.sync="search"
        hide-details
        @update:search="filterSearch"
        @update:modelValue="updateFilter"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No agents found
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>
  
  <script>
    import { ref, watchEffect } from "vue";

    export default {
      props: {
        agents: {
          type: Array,
          required: true,
        },
      },
      setup(props, { emit }) {
        const selectedAgents = ref(null);
        const search = ref("");
        const filteredAgents = ref(props.agents);

        // Update `filteredAgents` when `agents` prop changes
        watchEffect(() => {
          filteredAgents.value = props.agents;
        });

        // Filter agents based on search query
        const filterSearch = (val) => {
          if (val) {
            const lowercaseSearch = val.toLowerCase();
            filteredAgents.value = props.agents.filter((agent) =>
              agent.name.toLowerCase().includes(lowercaseSearch)
            );
          } else {
            filteredAgents.value = props.agents;
          }
        };

        // Emit selected agent IDs
        const updateFilter = () => {
          emit("update:filter", { key: "agent_ids", value: selectedAgents });
        };

        return {
          selectedAgents,
          search,
          filteredAgents,
          filterSearch,
          updateFilter,
        };
      },
    }
</script>
<style scoped>
  .agents-autocomplete {
    width: 200px; /* Adjust width */
    max-width: 100%; /* Prevent overflow */
  }
  .agents-label {
    font-weight: bold;
    margin-bottom: 7px;
    font-size: 14px;
    display: block;
  }
</style>