<template>
    <acms-v-modal
        :show="show"
        @hide="$emit('update:show', false)"
        size="sm-lg"
        :title="title"
    >
        <template #content>
            <div class="row">
                <acms-v-form-field
                    :modeTextarea="{minHeight:96,resize:true}"
                    label="Comment"
                    name="comment"
                    v-model="locState.form.comment"
                    :errors="locState.form.errors?.comment"
                />
            </div>
        </template>
        <template #footer="{hideHandle}">
            <v-btn :loading="isLoading" @click="sendHandle(hideHandle)" class="btn ms-auto btn-primary btn-sm">Send request
                <v-overlay absolute v-if="isLoading">
                    <v-progress-circular indeterminate size="24"></v-progress-circular>
                </v-overlay>  
            </v-btn>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalRequestWrongEmailOrPhone",
    computed:{
        title() {
            return 'Wrong ' + this.type
        }
    }
}
</script>
<script setup="">
import {reactive, ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {usePage} from "@inertiajs/vue3";
const emit = defineEmits(['update:show', ''])
const props = defineProps({
    show:Boolean,
    type:String,
    data:Object,
})

const locState = reactive({
    form:useForm({
        comment: null,
        type: props.type,
        id: props.data?.id ?? null
    } )
})

const isLoading = ref(false);
const AlertsManager = useAlertsManagerStore()
const $page = usePage()

watch(()=>props.show, (val)=>{
    if(!val){
        locState.form.comment = null;
        locState.form.type = props.type;
    }else{
        locState.form.id = props.data?.id ?? null;
    }
})

const sendHandle = (hide) => {
    isLoading.value = true;
    locState.form.post(getRoute('clients.contact.requestWrongContact'), {
        onSuccess(){
            isLoading.value = false;
            hide?.()
            AlertsManager.add($page.props.flash?.success);
        }
    })
    // hide?.()
}

</script>
<style scoped>

</style>
