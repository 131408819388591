<template>
    <div class="acms-v-table-pagination   ">
        <ul class=" d-flex mt-4 pagination justify-content-center" v-if="links?.length > 3">
            <li class="paginate_button page-item"
                v-for="link in links"
                :class="{'active': link.active}"
            >
                <template v-if="page">
                    <template v-if="link.label.includes('Prev')">
                        <acms-v-btn class="page-link"
                                    v-if="link.url"
                                    icon-size="20"
                                    icon="angle-left"
                                    @click="pageChangeHandle(link.label)"/>
                    </template>
                    <template v-else-if="link.label.includes('Next')">
                        <acms-v-btn class="page-link"
                                    icon-size="20"
                                    icon="angle-right"
                                    v-if="link.url"
                                    @click="pageChangeHandle(link.label)"/>
                    </template>
                    <template v-else>
                        <acms-v-btn class="page-link"
                                    @click="pageChangeHandle(link.label)"
                                    v-if="link.url"
                                    v-html="getPaginationLinkLabel(link.label)"/>
                    </template>
                </template>
                <template v-else>
                    <template v-if="link.label.includes('Prev')">
                        <Link class="page-link"
                              as="button"
                              v-if="link.url"
                              :href="link?.url">
                            <acms-v-icon name="angle-left" size="20"/>
                        </Link>
                    </template>
                    <template v-else-if="link.label.includes('Next')">
                        <Link class="page-link"
                              as="button"
                              v-if="link.url"
                              :href="link?.url"
                        >
                            <acms-v-icon name="angle-right" size="20"/>
                        </Link>
                    </template>
                    <template v-else>
                        <Link class="page-link" as="button"
                              v-if="link.url"
                              :href="link?.url"
                              v-html="getPaginationLinkLabel(link.label)"
                        />
                    </template>
                </template>
            </li>
        </ul>
    </div>
</template>
<script>
export default {name: 'AcmsVTablePagination'};
</script>

<script setup>
import {Link} from "@inertiajs/vue3";
import { router } from "@inertiajs/vue3";
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
    links: Array,
    page: [Number, String]
})
const emit = defineEmits(['update:page']);

const getPaginationLinkLabel = (label) => {
    if (label.includes(' &raquo;')) {
        return label.replace(' &raquo;', '')
    }
    if (label.includes('&laquo; ')) {
        return label.replace('&laquo;', '')
    }
    return label
}

const pageChangeHandle = (label) => {
    let newPage;
    
    // Adjust the page number based on the clicked link
    if (label.includes('Next')) {
        newPage = Number(props.page) + 1;
    } else if (label.includes('Previous')) {
        newPage = Number(props.page) - 1;
    } else {
        newPage = label;  // if it's a specific page number, use it directly
    }
    
    const filters = usePage().props?.filters ?? {};
    const limit  = usePage().props?.tableParams?.limit ?? 20
    const { url } = usePage();
    if (filters && Object.keys(filters).length > 0) {
        router.get(url, {page:newPage, limit:limit}, { preserveState: true});
    } else {
        emit('update:page', newPage)
    }
}

</script>

<style lang="scss">
.page-item {
    text-align: center;
    &.dots {
        line-height: 22px;
        .page-link {
            &:hover {
                border: none;
            }
        }
    }
    .page-link {
        padding: 6px 8px;
        font-size: 16px;
        line-height: 22px;
        height: 36px;
        min-width: 36px;
    }
    .page-button {
        padding: 7px 8px;
    }
}
</style>
