
import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

export default function useBackendMiddlewarePropsService() {
    const $page = usePage();

    const pageGlobalProps = $page.props

    const {
        genderOptions,
        contactSocialOptions,
        cabinClassOptions,
        clientMainValuesOptions,
        seatOptions,
        purposeOfTravelsOptions,
        numberOfStopsOptions,
        fareTypesOptions,
        pqTypesOptions,
        firstFivePccOptions,
        firstFiveMileageOptions
    } = pageGlobalProps?.optionsForSelect

    const permissions = pageGlobalProps?.permissions ?? []

    const backendCheckPermission = (...permissionNames) => {
      return  permissionNames.some(itm => permissions.includes(itm))
    }

    return {
        backendOptions: {
            genderOptions: genderOptions ?? [],
            contactSocialOptions: contactSocialOptions ?? [],
            cabinClassOptions: cabinClassOptions ?? [],
            clientMainValuesOptions: clientMainValuesOptions ?? [],
            seatOptions: seatOptions ?? [],
            purposeOfTravelsOptions: purposeOfTravelsOptions ?? [],
            numberOfStopsOptions: numberOfStopsOptions ?? [],
            fareTypesOptions: fareTypesOptions ?? [],
            pqTypesOptions: pqTypesOptions ?? [],
            firstFivePccOptions: firstFivePccOptions ?? [],
            firstFiveMileageOptions: firstFiveMileageOptions ?? [],
        },
        backendFlash : computed(()=> $page.props.flash),
        backendFlashSuccess : computed(()=> $page.props.flash.success),
        backendFlashError : computed(()=> $page.props.flash.error),
        backendContactViewRevealsLeft : computed(()=> pageGlobalProps?.contactViewRevealsLeft ?? '0'),


        backendCheckPermission,
        backendPermissions: {
            'allowCreateClient': backendCheckPermission('allowCreateClient'),
            'allowUpdateClientPhoneNumber': backendCheckPermission('allowUpdateClientPhoneNumber'),
            'allowUpdateClientEmail': backendCheckPermission('allowUpdateClientEmail'),
            'allowDeleteClientPhoneNumber': backendCheckPermission('allowDeleteClientPhoneNumber'),
            'allowDeleteClientEmail': backendCheckPermission('allowDeleteClientEmail'),
            'allowSendFollowUpFromRealAgents': backendCheckPermission('allowSendFollowUpFromRealAgents'),
            'allowViewClientStatus': backendCheckPermission('allowViewClientStatus'),
            'allowViewRequestAll': backendCheckPermission('allowViewRequestAll'),
            'allowUpdateRequest': backendCheckPermission(
                'allowUpdateRequestAll',
                'allowUpdateRequestTeam',
                'allowUpdateSelfRequest',
                'allowUpdateRequestDepartment'
            ),
            'allowCloseRequest': backendCheckPermission('allowCloseRequest'),
            'allowUseDraftMode': backendCheckPermission('allowUseDraftMode'),
            'allowCreateRequestPQ': backendCheckPermission('allowCreateRequestPQ'),
            'allowSendRequestPQ': backendCheckPermission('allowSendRequestPQ'),
            'allowCreateRequestPNRPQ': backendCheckPermission('allowCreateRequestPNRPQ'),
            'allowViewRequestActivityFeed': backendCheckPermission('allowViewRequestActivityFeed'),
            'allowViewPassengers': backendCheckPermission('allowViewPassengers'),
            'allowReadPassengers': backendCheckPermission('allowReadPassengers'),
            'allowCreatePassengers': backendCheckPermission('allowCreatePassengers'),
            'allowUpdatePassengers': backendCheckPermission('allowUpdatePassengers'),
            'allowDeletePassengers': backendCheckPermission('allowDeletePassengers'),
            'allowDeleteLinkedContact': backendCheckPermission('allowDeleteLinkedContact'),
            'allowUnAssignAgents': backendCheckPermission('allowUnAssignAgents'),
            'allowAssignAgents': backendCheckPermission('allowAssignAgents'),
            'allowButtonKayak': backendCheckPermission('allowButtonKayak'),
            'allowButtonSkyscanner': backendCheckPermission('allowButtonSkyscanner'),
            'allowButtonGflight': backendCheckPermission('allowButtonGflight'),
            'allowButtonPicasso': backendCheckPermission('allowButtonPicasso'),
            'allowButtonMatrix': backendCheckPermission('allowButtonMatrix'),
            'allowButtonBasis': backendCheckPermission('allowButtonBasis'),
            'allowSendPqFromRealAgents': backendCheckPermission('allowSendPqFromRealAgents'),
            'allowSendPqFromSystemAgents': backendCheckPermission('allowSendPqFromSystemAgents'),
            'allowTakeUnassigned': backendCheckPermission('allowTakeUnassigned'),
            'allowSendFollowUpFromSystemAgents': backendCheckPermission('allowSendFollowUpFromSystemAgents'),
            'allowDeleteActiveRequests': backendCheckPermission('allowDeleteActiveRequests'),
            'allowDeleteUnassignedRequests': backendCheckPermission('allowDeleteUnassignedRequests'),
            'allowDeleteActiveClients': backendCheckPermission('allowDeleteActiveClients'),
            'allowDeleteUnassignedClients': backendCheckPermission('allowDeleteUnassignedClients'),
            'allowViewAllReminders': backendCheckPermission('allowViewAllReminders'),
            'allowUnifireButtonProd': backendCheckPermission('allowUnifireButtonProd'),
            'allowUnifireButtonDev': backendCheckPermission('allowUnifireButtonDev'),
            'allowStartWhatsappChat': backendCheckPermission('allowStartWhatsappChat'),
            'allowAlternativeTerminal': backendCheckPermission('allowAlternativeTerminal'),
            'allowFilterByAgentName': backendCheckPermission('allowSearchRequestsByAgentName'),
            'allowFilterByCreationDate' : backendCheckPermission('allowSearchRequestsByCreationDate'),
            'allowFilterByDeparture' : backendCheckPermission('allowSearchRequestsByDeparture'),
            'allowFilterByDestination' : backendCheckPermission('allowSearchRequestsByDestination'),
            'allowFilterByDepartureDate' : backendCheckPermission('allowSearchRequestsByDepartureDate'),
            'allowFilterByReturnDate' : backendCheckPermission('allowSearchRequestsByReturnDate'),
            'allowFilterByPassengerType' : backendCheckPermission('allowSearchRequestsByPassengerType'),
            'allowFilterByActivity' : backendCheckPermission('allowSearchRequestsByActivity'),
            'allowFilterByPQsSentCount' : backendCheckPermission('allowSearchRequestsByPQsSentCount'),
            'allowFilterByFollowUpCount' : backendCheckPermission('allowSearchRequestsByFollowUpCount'),
            'allowFilterByEmailOpenedCount' : backendCheckPermission('allowSearchRequestsByEmailOpenedCount'),
            'allowFilterByOafOpenedCount' : backendCheckPermission('allowSearchRequestsByOafOpenedCount'),
            'allowFilterByClientSource' : backendCheckPermission('allowSearchRequestsByClientSource'),
        }


    }
}
