<template>
    <div>
        <label class="pqcount-label">PQ's sent</label> <!-- Label above -->
        <v-select
            v-model="selectedPQ"
            :items="pqOptions"
            class="pqcount-select"
            clearable
            dense
            variant="outlined"
            density="compact"
            @update:modelValue="updateFilter" 
        ></v-select>
    </div>
 </template>
  
  <script>
  import { ref } from "vue";
  
  export default {
    emits: ["update:filter"],
    setup(_, { emit }) {
        const selectedPQ = ref(null);
        const pqOptions = [
        { title: 'Any', value: null },
        { title: '0', value: 0 },
        { title: '≥1', value: 1 },
        { title: '≥2', value: 2 },
        { title: '≥3', value: 3 },
        { title: '≥4', value: 4 },
        { title: '≥5', value: 5 },
        { title: '≥6', value: 6 },
        { title: '≥7', value: 7 },
        { title: '≥8', value: 8 },
        { title: '≥9', value: 9 }
        ];
  
        const updateFilter = () => {
            emit("update:filter", { key: "PQSentCount", value: selectedPQ.value });
        };
  
        return { pqOptions, selectedPQ, updateFilter};
    }
  };
  </script>
<style scoped>
    .pqcount-select {
        width: 150px; /* Adjust width */
        max-width: 100%; /* Prevent overflow */
    }
    .pqcount-label {
        font-weight: bold;
        margin-bottom: 4px;
        display: block;
        font-size: 14px;
    }
</style>