<template>
    <div class="creation-date">
        <acms-v-form-field
          label="Creation date"
          placeholder="Select period"
          :modeDatepicker="{
            datesRange:true,
          }"
          v-model="creationDate"
          @update:modelValue="updateFilter"
          @click="addResetButton"
        />
      </div>
 </template>
  
  <script>
  import { ref } from "vue";
  
  export default {
    emits: ["update:filter"],
    setup(_, { emit }) {
      const creationDate = ref("");
  
      const updateFilter = () => {
        if (creationDate.value[0]) { // Emit only if a date is selected
          emit("update:filter", { key: "creationDate", value: creationDate.value });
        }
      };

      const addResetButton = () => {
        setTimeout(() => {
          const datepickerFooter = document.querySelector(".action-row");
          if (datepickerFooter && !datepickerFooter.querySelector(".reset-btn")) {
            document.querySelector(".close-button").classList.remove('ms-auto')
            const resetBtn = document.createElement("button");
            resetBtn.innerText = "Reset";
            resetBtn.classList.add("reset-btn");
            resetBtn.classList.add("close-button");
            resetBtn.classList.add("ms-auto");
            resetBtn.style.marginRight= '5px';
            resetBtn.style.background = 'red';
            resetBtn.onclick = () => {
              creationDate.value = []
              emit("update:filter", { key: "creationDate", value: [] });
            };
            datepickerFooter.prepend(resetBtn);
          }
        }, 100);
      };
  
      return { creationDate, updateFilter, addResetButton };
    }
  };
  </script>
  <style scoped>
    div:has(.dp__input_wrap) {
      width: 200px;
    }
    ::v-deep(.dp__input_wrap) {
        width: 200px;
    }
  </style>
