<script setup>
import { capitalize } from "lodash";
import { ref } from "vue";

const props = defineProps({
  modelValue: Object
});

const emit = defineEmits(["update:modelValue", "update:filter"]);

const passengers = ref({
  adults: props.modelValue?.adults || 1,
  children: props.modelValue?.children || 0,
  infants: props.modelValue?.infants || 0,
});

// Function to emit filter update based on the changed type
const updateFilter = (type) => {
  emit("update:filter", { key: 'Passenger' + capitalize(type), value: passengers.value[type] });
};
</script>

<template>
  <div class="box-people-quantity row g-3">
      <acms-v-form-field
          label="Adults"
          class="box-people-quantity-item col-4 col-xl-2 "
          :modeQuantity="{min:1, max:9}"
          v-model="passengers.adults"
          @update:modelValue="updateFilter('adults')" 
      />
      <acms-v-form-field
          label="Children"
          class="box-people-quantity-item col-4 col-xl-2"
          labelAfter="2-11"
          :modeQuantity="{min:0, max:9}"
          v-model="passengers.children"
          @update:modelValue="updateFilter('children')" 
      />

      <acms-v-form-field
          label="Infants"
          class="box-people-quantity-item col-4 col-xl-2"
          labelAfter="<2"
          :modeQuantity="{min:0, max:9}"
          v-model="passengers.infants"
          @update:modelValue="updateFilter('infants')" 
      />
  </div>
</template>

<style scoped lang="scss">
  .box-people-quantity-item{
    width: 110px;
      @media only screen and (max-width: 1630px) {
          width: calc(100% / 3);
      }
  }
</style>
