<template>
    <div>
        <label class="client-status-label">Active/Inactive</label> <!-- Label above -->
        <v-select
            v-model="selectedStatus"
            :items="statusOptions"
            class="client-status-select"
            clearable
            dense
            variant="outlined"
            density="compact"
            @update:modelValue="updateFilter" 
        ></v-select>
    </div>
 </template>
  
<script>
  import { ref } from "vue";
  
  export default {
    emits: ["update:filter"],
    setup(_, { emit }) {
        const selectedStatus = ref(null);
        const statusOptions = [
            { title: 'Any', value: null },
            { title: 'Active', value: 1 },
            { title: 'Inactive', value: 0 },
        ];
  
        const updateFilter = () => {
            emit("update:filter", { key: "ClientStatus", value: selectedStatus.value });
        };
  
        return { statusOptions, selectedStatus, updateFilter};
    }
  };
</script>
<style scoped>
    .client-status-select {
        width: 175px; /* Adjust width */
        max-width: 100%; /* Prevent overflow */
    }
    .client-status-label {
        font-weight: bold;
        margin-bottom: 7px;
        display: block;
        font-size: 14px;
    }
</style>